@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/font-awesome/scss/font-awesome';

@import 'colors';
@import 'fonts';

$main-font: 'Fira Sans', 'Source Sans Pro', 'Open Sans', 'Arial', sans-serif;
$headline-font: 'Zilla Slab', slab, serif;

$main-text-size: 17px;

@import 'elements';

// placeholder stuff
// move somewhere else

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}



html {
  height: 100%;
}

body {
  -webkit-font-smoothing: auto;
  -webkit-font-kerning: normal;
  -webkit-font-variant-ligatures: discretionary-ligatures;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box !important;
  color: $color-text-main;
  font-family: $main-font;
  font-size: $main-text-size;
  font-variant-ligatures: discretionary-ligatures;
  line-height: 1.7;
  min-height: 100%;
  position: relative;
  text-rendering: optimizeLegibility;
}

p.browserupgrade {
  border-bottom: 2px solid #000;
  margin: 10px auto;
  padding: 10px 30px;
}

div.area {
  padding: 4em 5em;

  @media (max-width: 991px) {
    padding: 4em 2em;
  }
}


.highlight {
  background: #8ee3fc;
  font-weight: 700;
  padding: 0 5px;
}

@import 'head';

div.area-1 {
  background-color: #000;
  color: #fff;
  font-size: $main-text-size * 1.3;
  line-height: $main-text-size * 2;
  padding: {
    bottom: 0;
    top: 0;
  }

  a {
    color: white;

    &:hover {
      color: $color-home-header-background-2;
      text-decoration: none;
    }
  }

  .col-md-6 {
    padding: 0;
  }

  .follow-social-media {
    text-align: right;

    @media (max-width: 991px) {
      text-align: center;
    }

    .follow-facebook,
    .follow-twitter {
      clip-path: polygon(8% 0, 100% 0%, 92% 100%, 0% 100%);
      color: #fff;
      display: inline-block;
      font-size: $main-text-size;
      font-weight: bolder;
      margin: .2em 0;
      padding: .1em .8em;
      text-decoration: none;
      white-space: nowrap;
    }

    .follow-facebook {
      background-color: $color-facebook;
    }

    .follow-twitter {
      background-color: $color-twitter;
    }
  }
}

div.area-3,
div.area-4,
div.area-5 {
  clip-path: polygon(50% 0, 100% 0, 100% 95%, 50% 100%, 0 95%, 0 0);
}

div.area-2,
div.area-4 {
  @include gradient-directional($start-color: #8ee3fc, $end-color: #ab9ffc);
}

.position-text {
  display: none;
  max-width: 960px;
  margin: 0 auto 1em;
}

ul.position-text {
  padding: 2em;
}

div.area-2 {
  clip-path: polygon(50% 0, 100% 0, 100% 95%, 50% 100%, 0 95%, 0 0);

  .positions {
    margin-bottom: 2em;

    .position {
      padding: 1em;
      text-align: center;
      vertical-align: middle;
      color: white;
      background-color: black;
      cursor: pointer;

      &:hover {
        background-color: #333;
      }

      h4 {
        font: {
          family: $headline-font;
          weight: bold;
          size: $main-text-size * 1.5;
        }
      }

      .fa {
        font-size: $main-text-size * 5;
      }
    }
    .position.col-sm-12 {
      background-color: #333;
    }
  }
}

div.area-5 {
  h2 {
    margin: 1em auto;
    text-align: center;
  }

  img {
    width: 100%;
    max-width: 1200px;
  }
}

div.area-6 {
  @include gradient-directional($start-color: #ba95fe, $end-color: #b87bfd);
  clip-path: polygon(50% 5%, 100% 0, 100% 100%, 50% 100%, 0 100%, 0 0);
}

div.area-8 {
  @include gradient-directional($start-color: #c375fe, $end-color: #d66afe);
}

.position-ignored {
  font: {
    size: $main-text-size * 1.5;
    family: $headline-font;
  }
  max-width: 960px;
  margin: 2em auto;
  text-align: center;
}

.issue1-description,
.issue2-description,
.issue3-description {
  display: none;
}

.hide-details {
  display: none;
}

.share-general,
.share-specific {
  > .row:nth-of-type(2) div:nth-child(even) {
    margin-top: .5em;
  }

  .statement,
  .statements {
    background-color: #fff;
    border-radius: 8px;
    font-weight: 300;
    margin-bottom: 1.4em;
    padding: 9px 18px;
    position: relative;

    &::after {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
      bottom: -10px;
      content: '';
      height: 0;
      left: 50px;
      position: absolute;
      width: 0;
    }
  }

  .statements {
    padding-left: 30px;
    padding-right: 30px;

    .statement2,
    .statement3 {
      display: none;
    }

    .statement-prev,
    .statement-next {
      cursor: pointer;
      position: absolute;
    }

    .statement-prev {
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    .statement-next {
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .share-facebook,
  .share-twitter {
    clip-path: polygon(8% 0, 100% 0%, 92% 100%, 0% 100%);
    color: #fff;
    display: inline-block;
    font-weight: bolder;
    margin-bottom: .5em;
    padding: .3em .8em;
    text-decoration: none;
    white-space: nowrap;
  }

  .share-facebook {
    background-color: $color-facebook;
  }

  .share-twitter {
    background-color: $color-twitter;
  }
}

div.footer {
  // Disable subpixel rendering for white-on-dark text
  -webkit-font-smoothing: antialiased;
  background-color: #556270;
  color: #fff;
  font-size: $main-text-size * .8;

  a {
    color: #ddd;
  }

  div.logos {
    a:first-child img {
      max-height: 80px;
    }
    a:nth-child(2) img {
      max-height: 95px;
    }
  }

  button,
  input,
  select {
    border: 1px solid #ddd;
  }
}

.toolkit {
  h2 {
    padding-top: 1.4em;
  }

  h3 {
    padding-top: 0.7em;
  }
}
