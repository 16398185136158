$black: #000;
$white: #fff;

$color-text-main: #383838;

$color-nav-logo-background: $black;
$color-nav-logo-text: $white;

$color-home-header-background-1: #6df2c7;
$color-home-header-background-2: #8df472;
$color-home-header-image-fallback: $white;
$color-home-header-title-decoration: rgba(56, 56, 56, .4);

$color-facebook: #3b5998;
$color-reddit: #ee2509;
$color-twitter: #4099ff;
