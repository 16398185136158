@for $i from 1 through 6 {
  h#{7 - $i} {
    font-size: $i * .4em;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: {
    family: $headline-font;
    weight: 700;
  }
  line-height: 1.2;
  padding-bottom: .7em;
}

h2,
h3 {
  text-transform: capitalize;
}

h3 {
  font: {
    family: $main-font;
    weight: 200;
  }
}

button,
input[type='button'],
input[type='submit'] {
  @extend .btn;
  @extend .btn-default;
}

input:not([type='submit'], [type='button']) {
  @extend .form-control;

  padding: 2px 5px;
}

.btn,
.btn-default,
input,
textarea,
select {
  text-shadow: none;

  &,
  &:hover,
  &:focus {
    border: {
      color: #000;
      radius: 0;
      style: solid;
      width: 1px;
    }
    color: $color-text-main;
  }
}

.btn,
.btn-default,
input,
textarea {
  min-height: 30px;
}

.btn,
.btn-default {
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, .1);
  }

  &:focus {
    box-shadow: none;
  }
}
