// Header navigation
.header-nav {
  padding: {
    bottom: 1em;
    left: 4em;
    right: 4em;
  }

  @media (max-width: 991px) {
    padding-left: 1em;
    padding-right: 1em;
  }

  nav {
    font-size: .8em;
    min-height: 0;

    .navbar-brand {
      background-color: $color-nav-logo-background;
      font: {
        family: $headline-font;
        size: 19px;
        weight: 600;
      }
      padding: 0 .4em;
      width: max-content;

      &,
      &:active,
      &:focus,
      &:hover {
        color: $color-nav-logo-text;
      }
    }

    .navbar-text {
      color: $color-text-main;
      font-weight: bolder;
      margin-right: 50px;
    }

    .navbar-text,
    .nav-link {
      white-space: nowrap;
    }
  }
}

#index-background-container {
  // Needed to make the absolute positioning below reference this div.
  position: relative;

  #index-header-background,
  #index-header-portraits {
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }

  #index-header-background {
    background-color: $color-home-header-background-1;
    @include gradient-directional($start-color: $color-home-header-background-2, $end-color: $color-home-header-background-1);

    // Fallback for browsers that don't support calc in clip-path
    clip-path: polygon(0 0, 100% 0%, 60% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, calc(100% - 450px) 100%, 0% 100%);
    z-index: -30;

    @media (max-width: 991px) {
      clip-path: none;
    }
  }

  #index-header-portraits {
    background: {
      color: $color-home-header-image-fallback;
      image: url('../img/bg-portraits.jpg');
      position: top right;
    }
    z-index: -31;
  }

  .header-nav {
    visibility: hidden;
  }
}

// First block on homepage
div.area-index-header {
  padding-bottom: 0;
  padding-top: 0;

  .row {
    padding-bottom: 2em;

    &:first-child {
      padding-bottom: .5em;
    }
  }

  #main-title {
    margin-top: .4em;
    padding-right: 80px;

    #main-title-strings {
      display: none;
    }

    #main-title-container,
    .typed-cursor {
      font: {
        family: $main-font;
        size: ($main-text-size * 2.8);
        weight: 700;
      }
    }

    #main-title-container {
      line-height: 1;
      overflow: visible;

      span {
        font-weight: 900;
      }
    }
  }

  .countdown {
    font-size: 1em;
    font-weight: 200;
    text-transform: uppercase;

    span {
      font-family: monospace;
      font-size: 2rem;
      margin-left: 20px;
    }
  }
}
